import {NgModule} from "@angular/core";

import * as analyticsServices from './services';

@NgModule({
  imports: [],
  providers: [...analyticsServices.services],
  exports: [],
})
export class AnalyticsModule {}
